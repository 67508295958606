import React from "react"
import * as styles from "./styles.module.scss"
import "scss/main.scss"

const LayoutWrapper = ({ children }) => {
	return (
		<>
			<div className={styles.content_wrapper}>{children}abc</div>
		</>
	)
}
export default LayoutWrapper
